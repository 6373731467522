import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

const Profit = () => import('@/views/sharingprofit/sharingprofit')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Modal = () => import('@/views/notifications/Modals')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Pekerjaans = () => import('@/views/pekerjaan/listpekerjaan')
const Tentangpupr = () => import('@/views/tentangpupr')
const TambahPekerjaans = () => import('@/views/pekerjaan/tambahpekerjaan')
const EditPekerjaans = () => import('@/views/pekerjaan/editpekerjaan')
const DetailPekerjaans = () => import('@/views/pekerjaan/detailpekerjaan')
const Packages = () => import('@/views/packages/listpackages')
const TambahPackages = () => import('@/views/packages/tambahpackage')
const EditPackages = () => import('@/views/packages/editpackage')

const Stake = () => import('@/views/stake/liststake')
const myStake = () => import('@/views/stake/mystake')
const DetailStake = () => import('@/views/stake/detailstake')

const DetailDepo = () => import('@/views/deposit/detaildeposit')

const Activities = () => import('@/views/activity/listactivity')

const Ticket = () => import('@/views/ticket/listticket')
const Faucet = () => import('@/views/faucet')

const Coins = () => import('@/views/coins/listcoins')
const TambahCoins = () => import('@/views/coins/tambahcoin')
const EditCoins = () => import('@/views/coins/editcoin')

const ProgressPekerjaans = () => import('@/views/progress/progresspekerjaan')


const User = () => import('@/views/user/listuser')
const TambahUsers = () => import('@/views/user/tambahuser')
const EditUsers = () => import('@/views/user/edituser')

const Member = () => import('@/views/member/listmember')
const TambahMembers = () => import('@/views/member/tambahmember')
const EditMembers = () => import('@/views/member/editmember')


const Refund = () => import('@/views/deposit/listrefund')

const Deposit = () => import('@/views/deposit/listdeposit')
const TambahDeposits = () => import('@/views/deposit/tambahdeposit')
const EditDeposits = () => import('@/views/deposit/editdeposit')

const Withdrawal = () => import('@/views/withdrawal/listwithdrawal')
const TambahWithdrawals = () => import('@/views/withdrawal/tambahwithdrawal')
const EditWithdrawals = () => import('@/views/withdrawal/editwithdrawal')


const InfoGrafis = () => import('@/views/infografis/infografis')
const Profilku = () => import('@/views/profilku/profilku')
const UbahPassword = () => import('@/views/ubahpassword/ubahpassword')
const Team = () => import('@/views/team/listteam')
const InfoMessenger = () => import('@/views/infomessenger/infomessenger')

const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const ResetPassword = () => import('@/views/pages/ResetPassword')
const SendPassword = () => import('@/views/pages/SendPassword')
const Confirmation = () => import('@/views/pages/Confirmation')

// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)
let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'tentangpupr',
          name: 'Tentang PUPR',
          component: Tentangpupr,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'stakepanel',
          name: 'Tools',
          component: Stake,
          meta: {
            requiresAuth: true
          }
        },        
        {
          path: 'stake',
          name: 'Stake List',
          component: Stake,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'mystake',
          redirect: '/mystake/list',
          name: 'Earning',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'Lists',
              component: myStake,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'detail/:txid',
              name: 'My Stake Detail',
              component: DetailStake,
              meta: {
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'Pekerjaan',
          name: 'Pekerjaan',
          component: Pekerjaans,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'pekerjaan',
          redirect: '/pekerjaan/list',
          name: 'Pekerjaanl',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'PekerjaanList',
              component: Pekerjaans,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Tambah Pekerjaan',
              component: TambahPekerjaans,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id',
              name: 'Edit Pekerjaan',
              component: EditPekerjaans,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'detail/:id',
              name: 'Detail Pekerjaan',
              component: DetailPekerjaans,
              meta: {
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'progresspekerjaan',
          name: 'progresspekerjaan',
          component: ProgressPekerjaans,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'activities',
          name: 'Activities',
          component: Activities,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'infografis',
          name: 'infografis',
          component: InfoGrafis,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'refunds',
          name: 'refunds',
          component: Refund,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'feesystem',
          name: 'sharingprofit',
          component: Profit,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'team',
          name: 'team',
          component: Team,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'myprofile',
          name: 'My Profile',
          component: Profilku,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'passwordku',
          name: 'Ubah Password',
          component: UbahPassword,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'infomessengerchat',
          name: 'Info Messenger',
          component: InfoMessenger,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'packages',
          redirect: '/packages/list',
          name: 'Packages',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Packages',
              component: Packages,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Package',
              component: TambahPackages,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_package',
              name: 'Edit Package',
              component: EditPackages,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'coins',
          redirect: '/coins/list',
          name: 'Stake Coins',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Coins',
              component: Coins,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Coin',
              component: TambahCoins,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_coin',
              name: 'Edit Coin',
              component: EditCoins,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'konsultanku/:id_package',
          name: 'Data Kontraktorku',
          component: EditPackages,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'faucet',
          name: 'Faucet',
          component: Faucet,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: 'deposits',
          redirect: '/deposits/list',
          name: 'Deposits',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Deposits',
              component: Deposit,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Deposit',
              component: TambahDeposits,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_user',
              name: 'Edit Deposit',
              component: EditDeposits,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'detail/:trn',
              name: 'My Detail Deposits',
              component: DetailDepo,
              meta: {
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'withdrawals',
          redirect: '/withdrawals/list',
          name: 'Withdrawals',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Withdrawals',
              component: Withdrawal,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Withdrawal',
              component: TambahWithdrawals,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_withdrawal',
              name: 'Edit Withdrawal',
              component: EditWithdrawals,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'ticket',
          redirect: '/ticket/list',
          name: 'Ticket',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Ticket Support',
              component: Ticket,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Ticket Support',
              component: TambahWithdrawals,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_withdrawal',
              name: 'Edit Withdrawal',
              component: EditWithdrawals,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'members',
          redirect: '/members/list',
          name: 'Members',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Members',
              component: Member,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add Member',
              component: TambahMembers,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_member',
              name: 'Edit Member',
              component: EditMembers,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
        {
          path: 'users',
          redirect: '/users/list',
          name: 'Users',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'list',
              name: 'List Users',
              component: User,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'add',
              name: 'Add User',
              component: TambahUsers,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'edit/:id_user',
              name: 'Edit User',
              component: EditUsers,
              meta: {
                requiresAuth: true
              }
            },
          ]
        },
      ]
    },
    {
      path: '/verify/:mail',
      name: 'Confirmation',
      component: Confirmation,
      meta: {
        guest: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        guest: true
      }
    },
    {
      path: '/register/:refer?',
      name: 'Register',
      component: Register,
      meta: {
        guest: true
      }
    },
    {
      path: '/resetpassword',
      name: 'Reset Password',
      component: SendPassword,
      meta: {
        guest: true
      }
    },
    {
      path: '/updatepassword/:mail',
      name: 'Update Password',
      component: ResetPassword,
      meta: {
        guest: true
      }
    },
    {
      path: '/modal',
      name: 'Modal',
      component: Modal,
      meta: {
        guest: true
      }
    },
    // {
    //   path: '/pages',
    //   redirect: '/pages/404',
    //   name: 'Pages',
    //   component: {
    //     render(c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: '500',
    //       name: 'Page500',
    //       component: Page500
    //     },
    //   ]
    // }
  ]
}

// Meta Handling


// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') === '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       let user = JSON.parse(localStorage.getItem('user'))
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       if (to.matched.some(record => record.meta.is_admin)) {
//         next({ path: '/dashboard' })
//       } else {
//         next()
//       }
//     }
//   } else if (to.matched.some(record => record.meta.guest)) {
//     if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == '') {
//       next({
//         path: '/login'
//       })
//     } else {
//       // alert('dashbord ' + localStorage.getItem('jwt'));
//       next({ path: '/dashboard' })

//     }
//   } else {
//     next()
//   }
// })

const redirectToLogin = route => {
  const LOGIN = 'Login';
  if (route.name != LOGIN) {
    return { name: LOGIN, replace: true, query: { redirectFrom: route.fullPath } };
  }
};

const redirectToHome = route => {
  const DEFAULT = 'Dashboard';
  return { name: DEFAULT, replace: true };
};

const DEFAULT_TITLE = 'Bitqueen - Meta Staking Project';
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE;
  });
});

router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requiresAuth)) {
  //   if (localStorage.getItem('jwt') == null || localStorage.getItem('jwt') == "") {
  //     router.push({ path: '/login' });
  //   } else {
  //     let user = JSON.parse(localStorage.getItem('user'))
  //     if (user == null) {
  //       if (user.level == 1) {
  //         router.push({ path: '/dashoard' });
  //       } else {
  //         // next({ path: '/dashboard' })
  //         router.push({ path: '/dashoard' });
  //       }
  //     } else {
  //       router.push({ path: '/login' });
  //     }
  //   }
  // } else if (to.matched.some(record => record.meta.guest)) {
  //   if (localStorage.getItem('jwt') == null) {
  //     router.push({ path: '/login' });
  //   } else {
  //     next({ path: '/dashboard' })
  //   }
  // } else {
  //   router.push({ path: '/login' });
  // }

  const userIsAuthenticated = localStorage.getItem('jwt');
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const guest = to.matched.some((route) => route.meta.guest);
  // if (localStorage.getItem('jwt') == null) {
  //   router.push({ path: '/login' });
  // } else {
  //   next({ path: '/dashboard' })
  // }
  // if (!userIsAuthenticated && to.fullPath === '/') {
  //   alert('ERROR1');
  //   return redirectToLogin(to);
  // }
  // alert(to.path);
  // if (to.path == '/register') {
  //   next();
  // }
  if (to.path !== '/login') {
    if (requiresAuth) {
      if (!userIsAuthenticated) {
        alert('Maaf! Anda harus login terlebih dahulu');
        router.push({ path: '/login' });
      }
      else {
        // alert('ERROR1');
        next();
      }
    }
    else {
      if(guest){
        next(); 
      }
      else{
        next({ path: '/login' });
      }
      
    }


    return true;
  }
  else {
    next()
  }
});

export default router;

